.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-glow {
  position: absolute;
  z-index: -1;
  height: auto;
  width: 300px;
  animation: fadeinout 4s infinite;
}

@keyframes fadeinout {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.search-select {
  width: 280px;
  height: 40px;
}

@media screen and (width >= 768px) {
  .background-glow {
    width: 600px;
  }

  .search-select {
    width: 568px;
    height: 80px;
  }
}

.option {
  padding: var(--space-4) 0;
}

.search-select input {
  font-size: var(--font_size_18);
}
