body {
  margin: 0;
  font-family:
    'Golos Text',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

@media screen and (width >= 768px) {
  body {
    font-size: 16px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

thead th {
  border-bottom: 1px solid var(--border_color);
}

tr th:first-child,
tr td:first-child {
  padding-left: 0 !important;
}

tr th:last-child,
tr td:last-child {
  padding-right: 0 !important;
}
