.paragraph-regular {
  font-family:
    'Golos Text',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-optical-sizing: auto;
  font-weight: 400;
}

.paragraph-bold {
  font-family:
    'Golos Text',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-optical-sizing: auto;
  font-weight: 700;
}

.title {
  font-family:
    'DM Serif Display',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-weight: 400 !important;
  font-style: normal;
}

.paragraph-14 {
  font-size: var(--font_size_14, 0.875em);
}

.paragraph-16 {
  font-size: var(--font_size_16, 1em);
}

.paragraph-18 {
  font-size: var(--font_size_18, 1.125em);
}
